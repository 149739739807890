import React, { FC, useEffect, useRef, useState } from 'react';
import { MdClose } from 'react-icons/md';

import s from './BurgerMenu.module.scss'
import { NavLink, useSearchParams } from 'react-router-dom';
import { fetchByAllInfo } from '../../../store/slice/infoSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { useTranslation } from 'react-i18next';
import { Link as ScrollLink } from 'react-scroll';
import Language from '../Language/Language';


interface BurgerMenuProps {
    setIsActiveBurger: (e: boolean) => void
    isActiveBurger: boolean
}

const BurgerMenu: FC<BurgerMenuProps> = ({ isActiveBurger, setIsActiveBurger }) => {
    const hadleVisib = () => {
        setIsActiveBurger(false)
    }
    const { t, i18n } = useTranslation()
    const { allInfo } = useAppSelector(state => state.info)
    const lang = i18n.language
    const dispatch = useAppDispatch()
    const { all_service } = useAppSelector(state => state.service)
    const blockRef = useRef<HTMLDivElement | null>(null)
    const [searchParams] = useSearchParams()
    const [query, setQuery] = useState(searchParams.get('id'))

    const toggleBlock = (e: MouseEvent) => {
        const { target } = e
        if (blockRef.current && !blockRef.current.contains(target as Node)
            && target instanceof HTMLElement && !target.className.includes('BurgerMenu')
            && !target.className.includes('BurgerAni') && !target.className.includes('menu_item')) {
            setIsActiveBurger(false)
        }
    }

    useEffect(() => {
        setQuery(searchParams.get('id'))
    }, [searchParams])

    useEffect(() => {
        document.addEventListener('mousedown', toggleBlock)

        return () => document.removeEventListener('mousedown', toggleBlock)
    }, [isActiveBurger])

    useEffect(() => {
        dispatch(fetchByAllInfo())
    }, [dispatch])

    useEffect(() => {
        // При рождении убрать скрол
        document.body.style.overflow = 'hidden'
        // При нажатии на ESC закрыть модальное окно
        document.addEventListener('keydown', (e) => {
            e.code === 'Escape' && hadleVisib()
        })
        // При рождении навесит другое событие на кнопку назад у браузера
        if (isActiveBurger) {
            window.history.pushState(null, '', window.location.href)
            window.onpopstate = () => hadleVisib();
        }
        return () => {
            // При закрытии  модального окна вернуть скролл
            document.body.style.overflow = 'auto'
            // При закрытии убрать действия с кнопки ESC
            document.removeEventListener('keydown', () => { })
            // При закрытии вернуть действие по умолчанию на кнопку назад в браузере
            if (!isActiveBurger) window.history.back();
            window.onpopstate = () => { };
        }
    }, [])
    return (
        <div ref={blockRef} onClick={(e) => e.stopPropagation()} className={isActiveBurger ? s.BurgerMenu : ` ${s.BurgerMenu} ${s.BurgerAni}`}>
            <ul>
                <MdClose className={s.close_icon} onClick={hadleVisib} />
                <li onClick={hadleVisib} className={s.menu_item}><NavLink to={'/about_company'} className={({ isActive }) =>
                    isActive ? `${s.menu_link} ${s.menu_link_active}` : s.menu_link
                }>{t('header.about_us')}</NavLink></li>
                <li onClick={hadleVisib} className={s.menu_item}> <ScrollLink onClick={hadleVisib} to="footer" smooth={true} duration={500} className={s.menu_link}>{t('header.contact')}</ScrollLink></li>
                <li onClick={hadleVisib} className={s.menu_item}><NavLink to={'/gallery'} className={({ isActive }) =>
                    isActive ? `${s.menu_link} ${s.menu_link_active}` : s.menu_link
                }>{t('header.gallery')}</NavLink></li>
                {
                    all_service.length > 0 &&
                    all_service.map(el => (
                        <li key={el.id} onClick={hadleVisib} className={s.menu_item}><NavLink to={`/services?id=${el.id}`} className={({ isActive }) =>
                            +el.id === (query && +query) ? `${s.menu_link} ${s.menu_link_active}` : s.menu_link
                        }>{el?.[`service_page_title_${lang}`]}</NavLink></li>
                    ))
                }
                <li onClick={hadleVisib}><NavLink to={`/service_cargo?id=0`} className={({ isActive }) =>
                    isActive ? `${s.menu_link} ${s.menu_link_active}` : s.menu_link
                }>{t("header.cargo")}</NavLink></li>
                <Language />
            </ul>
        </div>
    );
};


export default BurgerMenu;